import React, { useState } from "react";
import {
  Grommet,
  Form,
  FormField,
  RadioButtonGroup,
  Box,
  Button,
  TextArea,
  TextInput,
  CheckBox,
  Header,
  Text,
} from "grommet";
import { Link } from "react-router-dom";

const AppBar = (props) => (
  <Header
    background="brand"
    pad={{ left: "medium", right: "small", vertical: "small" }}
    elevation="medium"
    {...props}
  />
);

export default function PurhesePage() {
  const [orderType, setOrderType] = useState("inbox");
  const [location, setLocation] = useState("");
  const [total, setTotal] = useState("");
  const [paid, setPaid] = useState(false);
  const [delever, setDelever] = useState("express");
  const item = "My item";

  return (
    <Grommet full className="noto-sans-khmer">
      <AppBar>
        <Link to="/">
          <Button direction="row">Back</Button>
        </Link>
        <Text size="xxlarge">វិក័យបត្រ</Text>
        <div class="StyledBox__StyledBoxGap-sc-13pk1d4-1 dfakgH"></div>
      </AppBar>
      <Form className="p-5">
        <FormField label="Order Type">
          <RadioButtonGroup
            direction="row"
            name="orderType"
            options={["Inbox", "Live"]}
            value={orderType}
            onChange={(e) => setOrderType(e.target.value)}
          />
        </FormField>
        <FormField
          label="ទីតាំង"
          htmlFor="text-input"
          style={{
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <TextArea
            placeholder="ទីតាំង"
            name="location"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </FormField>
        <FormField label="Item">
          <TextInput value={item} name="item" readOnly />
        </FormField>
        <FormField label="Total">
          <Box direction="row" justify="between" className="relative">
            <span className="absolute left-3 top-2">$</span>
            <TextInput
              type="number"
              value={total}
              onChange={(e) => setTotal(e.target.value)}
              className="pl-20"
              placeholder="0.00"
              style={{ paddingLeft: "25px" }}
            ></TextInput>
          </Box>
        </FormField>
        <CheckBox
          checked={paid}
          label="paid"
          onChange={(event) => setPaid(event.target.checked)}
        />
        <FormField label="Deliver">
          <RadioButtonGroup
            direction="row"
            name="orderType"
            options={["Express", "CE", "VET"]}
            value={delever}
            onChange={(e) => setDelever(e.target.value)}
            alignContent="around"
          />
        </FormField>
        <Box direction="row" gap="medium" className="float-right">
          <Link to="/">
            <Button type="reset" label="Cancel" />
          </Link>
          <Button type="submit" primary label="Submit" />
        </Box>
      </Form>
    </Grommet>
  );
}
