import { Link } from "react-router-dom";

// This would typically come from an API or database
const products = [
  { id: 1, name: "Leather Jacket", image: "/files/blank-image.png" },
  { id: 2, name: "Denim Jeans", image: "/files/blank-image.png" },
  { id: 3, name: "Sneakers", image: "/files/blank-image.png" },
  { id: 4, name: "T-Shirt", image: "/files/blank-image.png" },
  { id: 5, name: "Sunglasses", image: "/files/blank-image.png" },
  { id: 6, name: "Watch", image: "/files/blank-image.png" },
];

export default function ProductsPage() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-6">Our Products</h1>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {products.map((product) => (
          <Link
            key={product.id}
            to={`/purchase?id=${product.id}`}
            className="group"
          >
            <div className="border rounded-lg overflow-hidden shadow-sm transition-shadow duration-300 group-hover:shadow-md">
              <div className="relative aspect-square">
                <img
                  src={product.image}
                  alt={product.name}
                  layout="fill"
                  objectFit="cover"
                  className="transition-transform duration-300 group-hover:scale-105"
                />
              </div>
              <div className="p-4">
                <h2 className="text-lg font-semibold group-hover:text-blue-600 transition-colors duration-300 ">
                  {product.name}
                </h2>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}
